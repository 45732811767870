// import dynamicImport from 'react-dynamicImport';
import React from 'react';
import { Spin } from 'antd';
import dynamicImport from '@/utilities/dynamicImport';

const _loadbleRoutes = {};
const SpinTo = (
  <div style={{
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }}
  >
    <Spin spinning />
  </div>
);

//-----------------------------------------
//	DEFAULT
//-----------------------------------------

_loadbleRoutes.API = dynamicImport({
  loader: () => import('@/pages/API/Api'),
  loading: () => SpinTo,
});

_loadbleRoutes.Index = dynamicImport({
  loader: () => import('@/components/index/IndexContainer'),
  loading: () => SpinTo,
});

_loadbleRoutes.Dashboard = dynamicImport({
	loader: () => import('@/pages/dashboard'),
	loading: () => SpinTo,
});

_loadbleRoutes.LoginContainer = dynamicImport({
  loader: () => import('@/pages/auth/Login'),
  loading: () => SpinTo,
});

_loadbleRoutes.LogoutContainer = dynamicImport({
  loader: () => import('@/pages/auth/Logout'),
  loading: () => SpinTo,
});

_loadbleRoutes.PageNotFound = dynamicImport({
  loader: () => import('@/pages/404'),
  loading: () => SpinTo,
});

_loadbleRoutes.Forbidden = dynamicImport({
  loader: () => import('@/pages/403/Forbidden'),
  loading: () => SpinTo,
});

_loadbleRoutes.LoggedOut = dynamicImport({
  loader: () => import('@/pages/logged-out'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Employees
//-----------------------------------------

_loadbleRoutes.EmployeesAllContainer = dynamicImport({
  loader: () => import('@/pages/employees/all'),
  loading: () => SpinTo,
});

_loadbleRoutes.EmployeesCreateContainer = dynamicImport({
  loader: () => import('@/pages/employees/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.EmployeesEditContainer = dynamicImport({
  loader: () => import('@/pages/employees/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.EmployeesProfile = dynamicImport({
  loader: () => import('@/pages/employees/profile'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Manufacture
//-----------------------------------------

_loadbleRoutes.ManufactureList = dynamicImport({
  loader: () => import('@/pages/manufacture/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ManufactureCreate = dynamicImport({
  loader: () => import('@/pages/manufacture/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.ManufactureEdit = dynamicImport({
  loader: () => import('@/pages/manufacture/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Platform
//-----------------------------------------

_loadbleRoutes.PlatformAndEqCreate = dynamicImport({
  loader: () => import('@/pages/platform&eq_group/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.PlatformAndEqEdit = dynamicImport({
  loader: () => import('@/pages/platform&eq_group/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.PlatformAndEqList = dynamicImport({
  loader: () => import('@/pages/platform&eq_group/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Profile
//-----------------------------------------

_loadbleRoutes.ProfileAdmin = dynamicImport({
  loader: () => import('@/pages/profile/ViewContainer'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProfileEdit = dynamicImport({
  loader: () => import('@/pages/profile/edit'),
  loading: () => SpinTo,
});

// Estimate

_loadbleRoutes.EstimateCountrySales = dynamicImport({
  loader: () => import('@/pages/total-country-sales'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Product
//-----------------------------------------

_loadbleRoutes.ProductCreate = dynamicImport({
  loader: () => import('@/pages/product/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductList = dynamicImport({
  loader: () => import('@/pages/product/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductEdit = dynamicImport({
  loader: () => import('@/pages/product/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductCurrent = dynamicImport({
  loader: () => import('@/pages/product/current'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Estimate
//-----------------------------------------

_loadbleRoutes.TotalCountryGoals = dynamicImport({
  loader: () => import('@/pages/country-goals'),
  loading: () => SpinTo,
});

_loadbleRoutes.TotalCountryGoalsV2 = dynamicImport({
	loader: () => import('@/pages/country-goals_v2'),
	loading: () => SpinTo,
});

_loadbleRoutes.OrderScheduledHistory = dynamicImport({
  loader: () => import('@/pages/order-scheduled-history'),
  loading: () => SpinTo,
});

_loadbleRoutes.OrdersScheduled = dynamicImport({
  loader: () => import('@/pages/orders-scheduled'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Dealer
//-----------------------------------------

_loadbleRoutes.DealerCreate = dynamicImport({
  loader: () => import('@/pages/dealer/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealerProfile = dynamicImport({
  loader: () => import('@/pages/dealer/profile'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealerEdit = dynamicImport({
  loader: () => import('@/pages/dealer/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealerList = dynamicImport({
  loader: () => import('@/pages/dealer/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Client
//-----------------------------------------

_loadbleRoutes.ClientList = dynamicImport({
  loader: () => import('@/pages/client/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientCreate = dynamicImport({
  loader: () => import('@/pages/client/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientProfile = dynamicImport({
  loader: () => import('@/pages/client/profile'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientEdit = dynamicImport({
  loader: () => import('@/pages/client/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Coverage goals
//-----------------------------------------

_loadbleRoutes.CoverageGoals = dynamicImport({
  loader: () => import('@/pages/coverage-goals'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Coverage goals v2
//-----------------------------------------

_loadbleRoutes.CoverageGoals_v2 = dynamicImport({
	loader: () => import('@/pages/coverage-goals_v2'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Territorial
//-----------------------------------------

_loadbleRoutes.TerritorialList = dynamicImport({
  loader: () => import('@/pages/territorial/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.TerritorialProfile = dynamicImport({
  loader: () => import('@/pages/territorial/profile'),
  loading: () => SpinTo,
});

_loadbleRoutes.TerritorialCreate = dynamicImport({
  loader: () => import('@/pages/territorial/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.TerritorialEdit = dynamicImport({
  loader: () => import('@/pages/territorial/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	pss
//-----------------------------------------

_loadbleRoutes.PssProfile = dynamicImport({
  loader: () => import('@/pages/pss/profile'),
  loading: () => SpinTo,
});

_loadbleRoutes.PssCreate = dynamicImport({
  loader: () => import('@/pages/pss/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.PssEdit = dynamicImport({
  loader: () => import('@/pages/pss/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Locations
//-----------------------------------------

_loadbleRoutes.CountryCreate = dynamicImport({
  loader: () => import('@/pages/countries/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.CountryEdit = dynamicImport({
  loader: () => import('@/pages/countries/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.CountryList = dynamicImport({
  loader: () => import('@/pages/countries/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Region
//-----------------------------------------

_loadbleRoutes.RegionCreate = dynamicImport({
  loader: () => import('@/pages/regions/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.RegionList = dynamicImport({
  loader: () => import('@/pages/regions/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.RegionEdit = dynamicImport({
  loader: () => import('@/pages/regions/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Rayon
//-----------------------------------------

_loadbleRoutes.RayonCreate = dynamicImport({
  loader: () => import('@/pages/rayons/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.RayonList = dynamicImport({
  loader: () => import('@/pages/rayons/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.RayonEdit = dynamicImport({
  loader: () => import('@/pages/rayons/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Job title
//-----------------------------------------

_loadbleRoutes.JobCreate = dynamicImport({
  loader: () => import('@/pages/job/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.JobList = dynamicImport({
  loader: () => import('@/pages/job/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.JobEdit = dynamicImport({
  loader: () => import('@/pages/job/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Factories
//-----------------------------------------

_loadbleRoutes.FactoryCreate = dynamicImport({
  loader: () => import('@/pages/factories/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.FactoriesList = dynamicImport({
  loader: () => import('@/pages/factories/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.FactoryEdit = dynamicImport({
  loader: () => import('@/pages/factories/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Product Line Group
//-----------------------------------------

_loadbleRoutes.ProductLineGroupEdit = dynamicImport({
  loader: () => import('@/pages/product-line-group/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductLineGroupCreate = dynamicImport({
  loader: () => import('@/pages/product-line-group/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Product Line
//-----------------------------------------

_loadbleRoutes.ProductLineEdit = dynamicImport({
  loader: () => import('@/pages/product-line/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductLineCreate = dynamicImport({
  loader: () => import('@/pages/product-line/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Farm Size
//-----------------------------------------

_loadbleRoutes.FarmSizeEdit = dynamicImport({
  loader: () => import('@/pages/farm-size/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.FarmSizeCreate = dynamicImport({
  loader: () => import('@/pages/farm-size/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.FarmSizeList = dynamicImport({
  loader: () => import('@/pages/farm-size/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Equipment Group
//-----------------------------------------

_loadbleRoutes.EquipmentGroupEdit = dynamicImport({
  loader: () => import('@/pages/equipment-group/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.EquipmentGroupCreate = dynamicImport({
  loader: () => import('@/pages/equipment-group/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Composite Level
//-----------------------------------------

_loadbleRoutes.CompositeLevelEdit = dynamicImport({
  loader: () => import('@/pages/composite-level/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.CompositeLevelCreate = dynamicImport({
  loader: () => import('@/pages/composite-level/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Estimate Description
//-----------------------------------------

_loadbleRoutes.EstimateDescriptionEdit = dynamicImport({
  loader: () => import('@/pages/estimate-description/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.EstimateDescriptionCreate = dynamicImport({
  loader: () => import('@/pages/estimate-description/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Model Description
//-----------------------------------------

_loadbleRoutes.ModelDescriptionEdit = dynamicImport({
  loader: () => import('@/pages/model-description/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.ModelDescriptionCreate = dynamicImport({
  loader: () => import('@/pages/model-description/create'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Base Machinery Description
//-----------------------------------------

_loadbleRoutes.BaseMachineryDescriptionCreate = dynamicImport({
  loader: () => import('@/pages/base-machinery-description/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.BaseMachineryDescriptionEdit = dynamicImport({
  loader: () => import('@/pages/base-machinery-description/edit'),
  loading: () => SpinTo,
});

// ----------------------------------------------------------------------
// Size Parameters
// ----------------------------------------------------------------------

_loadbleRoutes.SizeParameterList = dynamicImport({
  loader: () => import('@/pages/size-parameters/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.SizeParameterCreate = dynamicImport({
  loader: () => import('@/pages/size-parameters/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.SizeParameterEdit = dynamicImport({
  loader: () => import('@/pages/size-parameters/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Co-worker
//-----------------------------------------

_loadbleRoutes.CoWorkerProfile = dynamicImport({
  loader: () => import('@/pages/co-worker/profile'),
  loading: () => SpinTo,
});

_loadbleRoutes.CoWorkerCreate = dynamicImport({
  loader: () => import('@/pages/co-worker/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.CoWorkerEdit = dynamicImport({
  loader: () => import('@/pages/co-worker/edit'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Staff
//-----------------------------------------

_loadbleRoutes.StaffList = dynamicImport({
  loader: () => import('@/pages/staff/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Country Manager
//-----------------------------------------

_loadbleRoutes.CountryManagerProfile = dynamicImport({
  loader: () => import('@/pages/country-manager/profile'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Simple CRUD test
//-----------------------------------------

_loadbleRoutes.Test = dynamicImport({
  loader: () => import('@/pages/testSimpleCRUD/Create'),
  loading: () => SpinTo,
});

_loadbleRoutes.TestUpdate = dynamicImport({
  loader: () => import('@/pages/testSimpleCRUD/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.TestList = dynamicImport({
  loader: () => import('@/pages/testSimpleCRUD/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Currency
//-----------------------------------------


_loadbleRoutes.CurrencyCreate = dynamicImport({
  loader: () => import('@/pages/currency/Create'),
  loading: () => SpinTo,
});

_loadbleRoutes.CurrencyUpdate = dynamicImport({
  loader: () => import('@/pages/currency/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.CurrencyList = dynamicImport({
  loader: () => import('@/pages/currency/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Deal Status
//-----------------------------------------

_loadbleRoutes.DealStatusUpdate = dynamicImport({
  loader: () => import('@/pages/deal-status/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealStatusList = dynamicImport({
  loader: () => import('@/pages/deal-status/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Deal Actions
//-----------------------------------------

_loadbleRoutes.DealActionsUpdate = dynamicImport({
  loader: () => import('@/pages/deal-action/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealActionsCreate = dynamicImport({
  loader: () => import('@/pages/deal-action/Create'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealActionsList = dynamicImport({
  loader: () => import('@/pages/deal-action/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Deal Results
//-----------------------------------------

_loadbleRoutes.DealResultsCreate = dynamicImport({
  loader: () => import('@/pages/deal-result/Create'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealResultsUpdate = dynamicImport({
  loader: () => import('@/pages/deal-result/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealResultsList = dynamicImport({
  loader: () => import('@/pages/deal-result/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	Deal Lost Reasons
//-----------------------------------------

_loadbleRoutes.DealLostReasonsCreate = dynamicImport({
  loader: () => import('@/pages/deal-lost-reason/Create'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealLostReasonsUpdate = dynamicImport({
  loader: () => import('@/pages/deal-lost-reason/Update'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealLostReasonsList = dynamicImport({
  loader: () => import('@/pages/deal-lost-reason/List'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	BOED
//-----------------------------------------

_loadbleRoutes.BOEDList = dynamicImport({
  loader: () => import('@/pages/BOED/list'),
  loading: () => SpinTo,
});

//-----------------------------------------
//	BOED
//-----------------------------------------

_loadbleRoutes.BOEDList_v2 = dynamicImport({
	loader: () => import('@/pages/BOED_v2/list'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Deals
//-----------------------------------------

_loadbleRoutes.DealList = dynamicImport({
  loader: () => import('@/pages/deals/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealVehicleEdit = dynamicImport({
  loader: () => import('@/pages/deals/vehicle/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.TradeInVehicleEdit = dynamicImport({
  loader: () => import('@/pages/deals/trade-in/edit'),
  loading: () => SpinTo,
});

_loadbleRoutes.TradeInVehicleCreate = dynamicImport({
  loader: () => import('@/pages/deals/trade-in/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.DealCurrent = dynamicImport({
  loader: () => import('@/pages/deals/info'),
  loading: () => SpinTo,
});

_loadbleRoutes.TranslateModule = dynamicImport({
  loader: () => import('@/pages/translate-module/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ContactCreate = dynamicImport({
  loader: () => import('@/pages/contacts/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.ContactUpdate = dynamicImport({
  loader: () => import('@/pages/contacts/update'),
  loading: () => SpinTo,
});

_loadbleRoutes.TechniqueCreate = dynamicImport({
  loader: () => import('@/pages/technique/create'),
  loading: () => SpinTo,
});

_loadbleRoutes.TechniqueUpdate = dynamicImport({
  loader: () => import('@/pages/technique/update'),
  loading: () => SpinTo,
});

_loadbleRoutes.TechniqueList = dynamicImport({
  loader: () => import('@/pages/technique/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.NotificationEmails = dynamicImport({
  loader: () => import('@/pages/notification-emails/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientVehicleImport = dynamicImport({
  loader: () => import('@/pages/vehicle-import/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientVehicleImportView = dynamicImport({
  loader: () => import('@/pages/vehicle-import/view'),
  loading: () => SpinTo,
});

_loadbleRoutes.ClientVehicleUpdateImport = dynamicImport({
	loader: () => import('@/pages/vehicle-update-import/list'),
	loading: () => SpinTo,
});

_loadbleRoutes.ClientVehicleUpdateImportView = dynamicImport({
	loader: () => import('@/pages/vehicle-update-import/view'),
	loading: () => SpinTo,
});

_loadbleRoutes.IosLinksImport = dynamicImport({
  loader: () => import('@/pages/ios-links-import/list'),
  loading: () => SpinTo,
});

_loadbleRoutes.IosLinksImportView = dynamicImport({
  loader: () => import('@/pages/ios-links-import/view'),
  loading: () => SpinTo,
});

_loadbleRoutes.Analytics = dynamicImport({
  loader: () => import('@/pages/analytics'),
  loading: () => SpinTo,
});

_loadbleRoutes.Analytics_v2 = dynamicImport({
	loader: () => import('@/pages/analytics_v2'),
	loading: () => SpinTo,
});

_loadbleRoutes.ProductsQuantity = dynamicImport({
  loader: () => import('@/pages/analytics/products-quantity'),
  loading: () => SpinTo,
});

_loadbleRoutes.ProductsQuantity_v2 = dynamicImport({
	loader: () => import('@/pages/analytics_v2/products-quantity'),
	loading: () => SpinTo,
});

_loadbleRoutes.lostToCompanies = dynamicImport({
  loader: () => import('@/pages/lost-to-companies'),
  loading: () => SpinTo,
});

_loadbleRoutes.salesEfficiency = dynamicImport({
  loader: () => import('@/pages/sales-efficiency'),
  loading: () => SpinTo,
});

_loadbleRoutes.salesEfficiency_v2 = dynamicImport({
	loader: () => import('@/pages/sales-efficiency_v2'),
	loading: () => SpinTo,
});

_loadbleRoutes.Maps = dynamicImport({
	loader: () => import('@/pages/maps'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Actual sales plan import
//-----------------------------------------

_loadbleRoutes.ActualSalesPlanImport = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-import/list'),
	loading: () => SpinTo,
});

_loadbleRoutes.ActualSalesPlanImportView = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-import/view'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Actual sales plan draft
//-----------------------------------------

_loadbleRoutes.ActualSalesPlanDraft = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-draft/list'),
	loading: () => SpinTo,
});

_loadbleRoutes.actualSalesPlanDraftEdit = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-draft/edit'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Actual sales plans
//-----------------------------------------

_loadbleRoutes.ActualSalesPlans = dynamicImport({
	loader: () => import('@/pages/actual-sales-plans/list'),
	loading: () => SpinTo,
});

// _loadbleRoutes.actualSalesPlansEdit = dynamicImport({
// 	loader: () => import('@/pages/actual-sales-plans/edit'),
// 	loading: () => SpinTo,
// });

//-----------------------------------------
//	Actual sales plan maps
//-----------------------------------------

_loadbleRoutes.ActualSalesPlanMaps = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-maps'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Actual sales plan deal maps
//-----------------------------------------

_loadbleRoutes.ActualSalesPlanDealMaps = dynamicImport({
	loader: () => import('@/pages/actual-sales-plan-deal-maps'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Actual sales plans summary
//-----------------------------------------

_loadbleRoutes.ActualSalesPlansSummary = dynamicImport({
	loader: () => import('@/pages/actual-sales-plans-summary'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Service codes
//-----------------------------------------

_loadbleRoutes.ServiceCodesList = dynamicImport({
	loader: () => import('@/pages/service-codes/list'),
	loading: () => SpinTo,
});

_loadbleRoutes.ServiceCodesCreate = dynamicImport({
	loader: () => import('@/pages/service-codes/create'),
	loading: () => SpinTo,
});

_loadbleRoutes.ServiceCodesEdit = dynamicImport({
	loader: () => import('@/pages/service-codes/edit'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Equipment operation times
//-----------------------------------------

_loadbleRoutes.EquipmentOperationTimes = dynamicImport({
	loader: () => import('@/pages/equipment-operation-times'),
	loading: () => SpinTo,
});

//-----------------------------------------
//	Potential sales
//-----------------------------------------

_loadbleRoutes.PotentialSales = dynamicImport({
	loader: () => import('@/pages/potential-sales'),
	loading: () => SpinTo,
});

_loadbleRoutes.PotentialSalesEdit = dynamicImport({
	loader: () => import('@/pages/potential-sales/edit'),
	loading: () => SpinTo,
});

export const route = _loadbleRoutes;

export default _loadbleRoutes;
