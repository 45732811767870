import { Text } from 'recharts';
import React from 'react';

export const XAxisVerticalTick = ({ x, y, payload, width }) => {
	if (payload?.value) {
		return (
			<Text
				width={width}
				x={x}
				y={y}
				textAnchor="end"
				verticalAnchor="middle"
				transform={`rotate(-90, ${x}, ${y})`}
			>
				{payload.value}
			</Text>
		);
	}
	return null;
};
