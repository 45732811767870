import React from 'react';
import { Col, Modal, Row } from 'antd';
import t from '@/utilities/translate';
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { XAxisVerticalTick } from './XAxisVerticalTick';

const ChartModal = (props) => {
	const {
		modalProps,
		closeModal: closeModalFn,
		data,
	} = props;

	const formatYTick = (value, isPercent = false) => {
		if (isPercent) {
			return `${value} %`;
		} else {
			return value;
		}
	};

	const renderTooltipContent = (props, isPercent = false) => {
		const { payload, label } = props;

		return (
			<div className={'recharts-tooltip'}>
				<b>{label}</b>
				<div>
					Value: {payload[0]?.payload.value}{isPercent ? '%' : ''}
				</div>
			</div>
		)
	};

	const absoluteValuesKeys = [
		'nextActionMeeting',
		'nextActionPhoneCall',
		'nextActionMeetingWithClientCount',
		'nextActionPhoneCallWithClientCount',
		'nextActionWithoutMeetingAndPhoneCall',
		'nextActionWithoutMeetingAndPhoneCallWithClientCount',
		'salesManagerLogin',
		'salesManagerCreateDeal',
		'salesManagerUpdateDeal',
		'salesManagerViewDeal',
		'clientVehiclesCreated',
		'contactsCreated',
		'won',
		'marketShareEst'
	];
	const relativeValuesKeys = [
		'nextActionMeetingWithClient',
		'nextActionPhoneCallWithClient',
		'nextActionWithoutMeetingAndPhoneCallWithClient',
		'nextActionWithClient',
		'nextActionMoreTwoTimeWithClient',
		'gapToTheGoal',
	];

	const dataSourceAbsolute = absoluteValuesKeys.map((key) => ({
		name: t(`sales-efficiency/list/${key}`),
		value: data[key],
	}));
	const dataSourceRelative = relativeValuesKeys.map((key) => ({
		name: t(`sales-efficiency/list/${key}`),
		value: data[key],
	}));

	return (
		<Modal {...modalProps} title={data.user} footer={null} className={'sales-efficiency-modal'}>
			<Row type={'flex'} gutter={16}>
				<Col xs={24} lg={15}>
					<div style={{ width: '100%', overflow: 'auto hidden' }}>
						<div style={{ minWidth: dataSourceAbsolute.length * 45, width: '100%' }}>
							<ResponsiveContainer width={'100%'} height={500}>
								<BarChart
									data={dataSourceAbsolute}
									barSize={60}
									margin={{ bottom: 150, left: 0, top: 20, right: 20 }}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="name"
										type={'category'}
										interval={0}
										tick={<XAxisVerticalTick width={150} />}
									/>
									<YAxis tickFormatter={formatYTick} />
									<Tooltip animationDuration={0} content={renderTooltipContent} />
									<Bar dataKey={'value'} fill={'#245F19'} />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
				</Col>
				<Col xs={24} lg={9}>
					<div style={{ width: '100%', overflow: 'auto hidden' }}>
						<div style={{ minWidth: dataSourceRelative.length * 45, width: '100%' }}>
							<ResponsiveContainer width={'100%'} height={500}>
								<BarChart
									data={dataSourceRelative}
									barSize={60}
									margin={{ bottom: 150, left: 0, top: 20, right: 20 }}
								>
									<CartesianGrid strokeDasharray="3 3" />
									<XAxis
										dataKey="name"
										type={'category'}
										interval={0}
										tick={<XAxisVerticalTick width={150} />}
									/>
									<YAxis tickFormatter={(props) => formatYTick(props, true)} />
									<Tooltip animationDuration={0}
											 content={(props) => renderTooltipContent(props, true)} />
									<Bar dataKey={'value'} fill={'#245F19'} />
								</BarChart>
							</ResponsiveContainer>
						</div>
					</div>
				</Col>
			</Row>
		</Modal>
	);
};

export default ChartModal;
