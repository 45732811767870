//-----------------------------------------
//	list
//-----------------------------------------

export const FETCH_IMPORTS_LIST = '@/client-vehicle-update-import/FETCH_IMPORTS_LIST';
export const SET_IMPORTS_LIST = '@/client-vehicle-update-import/SET_IMPORTS_LIST';

//-----------------------------------------
//	Import info
//-----------------------------------------

export const GET_IMPORT_INFO = '@/client-vehicle-update-import/GET_IMPORT_INFO';
export const SET_IMPORT_INFO = '@/client-vehicle-update-import/SET_IMPORT_INFO';

//-----------------------------------------
//	Check if can upload
//-----------------------------------------

export const CHECK_UPLOAD_AVAILABILITY = '@/client-vehicle-update-import/CHECK_UPLOAD_AVAILABILITY';
export const SET_UPLOAD_AVAILABILITY = '@/client-vehicle-update-import/SET_UPLOAD_AVAILABILITY';

//-----------------------------------------
//	Upload file
//-----------------------------------------

export const UPLOAD_FILE = '@/client-vehicle-update-import/UPLOAD_FILE';

//-----------------------------------------
//	Filter values
//-----------------------------------------

export const SET_FILTER_VALUE = '@/client-vehicle-update-import/SET_FILTER_VALUE';
