import { put, call, takeEvery } from 'redux-saga/effects';
import fileDownload from 'js-file-download';
import moment from 'moment';
import * as types from './types';
import http from '@/services/http';
import T from '@/utilities/translate';
import { addPreloader, removePreloader } from '@/redux/common/preloaders';
import { noty } from '@/utilities/notifications';
import { formats } from '@/utilities/formatsData';

export const exportFile = (payload) => ({ type: types.EXPORT_FILE, payload });

const map = {
	employees: {
		url: '/employee-export',
		ext: 'xlsx',
		name: 'salesmen_view'
	},
	products: {
		url: '/products-export',
		ext: 'xlsx'
	},
	Customers: {
		url: '/clients-export',
		ext: 'xlsx'
	},
	CustomerVehicles: {
		url: '/client-vehicles-export',
		ext: 'xlsx',
		successMessageKey: 'clients/vehicles-export/success',
		skipFile: true
	},
	Employees: {
		url: '/staff-export',
		ext: 'xlsx'
	},
	Dealers: {
		url: '/dealers-export',
		ext: 'xlsx'
	},
	Deals: {
		url: '/deals-export',
		ext: 'xlsx'
	},
	ArchivedDeals: {
		url: '/archived-deals-export',
		ext: 'xlsx'
	},
	ActualSalesPlanDrafts: {
		url: '/actual-sales-plan-drafts-export',
		ext: 'xlsx'
	},
	ActualSalesPlans: {
		url: '/actual-sales-plans-export',
		ext: 'xlsx'
	},
	ActualSalesPlansSummary: {
		url: '/actual-sales-plans-summary-export',
		ext: 'xlsx'
	},
	PotentialSales: {
		url: '/potential-sales-export',
		ext: 'xlsx'
	},
};

export function* exportWorker({ payload }) {
	let { ns, params = {}, urlParams = {} } = payload;
	params = formats(params);
	if (ns in map) {
		let { url, name, successMessageKey = 'export/success', skipFile } = map[ns];

		if (typeof map[ns].url === 'function') {
			url = map[ns].url(urlParams);
		}

		yield put(addPreloader(`@export/${ns}`));

		try {
			const response = yield call(http.get, url, {
				params,
				responseType: 'blob'
			}, {
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST, OPTIONS'
				},
			});

			if (response) {
				if (response.status === 204) {
					yield call(noty, 'warning', 'Данные для экспорта не найдены');
				} else {
					yield !skipFile && call(fileDownload, response.data, `${name || ns}_${moment().format('DDMMYYYY')}.${map[ns].ext}`);
					yield call(noty, 'success', T(successMessageKey));
				}
			}
		} catch ({ response }) {
			console.error(response);
		} finally {
			yield put(removePreloader(`@export/${ns}`));
		}
	}
}

export function* exportWatcher() {
	yield takeEvery(types.EXPORT_FILE, exportWorker);
}
